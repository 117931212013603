<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" 
          />
          <!-- <c-btn 
            v-if="editable&&popupParam.eduCourseId" 
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="removeInfo"
          /> -->
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <c-text
            required
            :editable="editable"
            label="교육과정명"
            name="educationCourseName"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            required
            :editable="editable" 
            :isEnterprise="true"
            type="edit" 
            name="plantCd" 
            v-model="educationInfo.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="법정교육여부"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag"
            @datachange="legalDatachange">
          </c-checkbox>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :required="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="교육주기"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :suffix="$language('원')"
            type="number"
            :editable="editable"
            label="예상교육비"
            name="estimatedEducationExpenses"
            v-model="educationInfo.estimatedEducationExpenses">
          </c-text>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label="주요 대상자"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :required="true"
            :editable="editable"
            label="교육장소"
            name="educationLocation"
            v-model="educationInfo.educationLocation">
          </c-text>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <c-multi-select
            required
            :editable="editable"
            :isArray="false"
            codeGroupCd="RELATED_LAWS_CD"
            itemText="codeName"
            itemValue="code"
            label="관련법규"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-text
            required
            :editable="editable"
            label="학습목적"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="사용여부"
            name="useFlag"
            v-model="educationInfo.useFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            type="edit"
            :comboItems="comboItems"
            itemText="codeName"
            itemValue="code"
            itemDisable="disable"
            name="educationMethodCd"
            label="교육방법"
            v-model="educationInfo.educationMethodCd"
            @datachange="educationMethodChange"
          >
            <template v-slot:suffixLabel>
              <q-icon name="info" color="orange" style="font-size: 1.4em; margin-left: 10px;">
                <q-tooltip anchor="center right" self="center left" :offset="[10, 10]" content-style="font-size: 0.9em" >
                  {{$language('교육교재 섹션에서 동영상 파일 첨부시 동영상 선택 가능')}}
                </q-tooltip>
              </q-icon>
            </template>
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :disabled="educationInfo.educationMethodCd != 'EMC999999'"
            :editable="editable"
            :comboItems="educationVodItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="vod"
            label="교육용 동영상"
            v-model="educationInfo.vod"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="문제풀이여부"
            name="eduQuestionFlag"
            v-model="educationInfo.eduQuestionFlag"
            @input="input"
          >
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-edu-quiz 
            :disabled="!isQuestion"
            :required="isQuestion"
            :editable="editable"
            label="문제풀이SET"
            name="eduQuestionMstId"
            v-model="educationInfo.eduQuestionMstId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-text
            :disabled="!isQuestion"
            :required="isQuestion"
            :editable="editable"
            type="number"
            label="통과기준점수"
            name="eduQuestionPassScore"
            v-model="educationInfo.eduQuestionPassScore">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="table"
          title="교육내용"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="educationInfo.eduCourseSubjectList"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
          :isExcelDown="false"
          :editable="editable"
          rowKey="subjectNo"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
                  <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
              <!-- 제외 -->
              <c-btn v-if="editable && educationInfo.eduCourseSubjectList.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            </q-btn-group>
          </template>
          <template v-if="educationInfo.eduCourseSubjectList && educationInfo.eduCourseSubjectList.length>0" slot="suffixTitle">
              <font class="text-negative" style="font-size:0.8em;font-weight:300;">
                {{$language('※ 총 교육소요시간 : ')}}
              </font>
              <q-chip
                outline square 
                color="primary"
                text-color="white"
                class="q-ma-none customchipdept">
                <template>
                  {{ formattedEducationTime }}
                </template>
              </q-chip>
            </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 attach">
        <c-upload 
          :attachInfo="attachInfo"
          :editable="editable"
          label="교육교재"
          @files="files">
        </c-upload>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-coursen-info',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
    educationInfo: {
      type: Object,
      default: () => ({
        eduCourseId: '',  // 교육과정 일련번호
        plantCd: '',  // 사업장 코드
        educationCourseName: '',  // 교육과정명
        educationKindCdLarge: null,  // 교육종류1
        educationKindCdSmall: null,  // 교육종류2
        educationTypeCd: null,  // 전사자체/부서자체/사내위탁/사외교육/연수/기타
        legalEducationFlag: 'Y',  // 법정교육 여부
        sumEducationTime: '',  // 교육시간
        educationCycle: null,  // 주기
        educationPurpose: '',  // 학습목적
        mainmainTargetAudience: '',  // 기술
        estimatedEducationExpenses: '',  // 예상 교육비
        relationLaws: '',  // 관련 법규
        useFlag: 'Y',
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: null,
        educationLocation: '', // 교육장소
        educationMethodCd: null, //교육방법
        vod: null, // 동영상교육소스
        regUserId: '',
        regUserName: '', 
        regUserDeptName: '',
        regDtStringTime: '',
        regDt: '',
        chgUserId: '',
        chgUserName: '',
        chgUserDeptName: '',
        chgDtStringTime: '',
        chgDt: '',
        eduCourseSubjectList: [], // 과정별 교육과목
        deleteEduCourseSubjectList: [], // 제외 교육과목
        eduCourseUserList: [], // 대상자
        deleteEduCourseUserList: [], // 제외 대상자
        eduCourseDeptList: [], // 대상부서
        deleteEduCourseDeptList: [], // 제외 대상부서
        eduCourseSeniorList: [], // 대상 선임
        deleteEduCourseSeniorList: [], // 제외 대상 선임
      }),
    },
  },
  data() {
    return {
      educationVodItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'subjectName',
            field: 'subjectName',
            // 과목 및 세부내용
            label: '과목 및 세부내용',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'educationTime',
            field: 'educationTime',
            label: '교육소요시간',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            type: 'text',
            // 강사명
            label: '강사명',
            style: 'width:80px',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '300px'
      },
      editable: true,
      userListUrl: '',
      userSaveUrl: '',
      userDeleteUrl: '',
      deptListUrl: '',
      deptSaveUrl: '',
      deptDeleteUrl: '',
      seniorListUrl: '',
      seniorSaveUrl: '',
      seniorDeleteUrl: '',
      saveUrl: '',
      saveType: '',
      isSave: false,
      checkUrl: '', 
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_CURRICULUM',
        taskKey: '',
      },
      comboItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isQuestion() {
      return this.educationInfo.eduQuestionFlag == 'Y'
    },
    // 총 교육소요시간 계산을 수행하는 계산 속성
    formattedEducationTime() {
      let totalMinutes = 0;
      this.educationInfo.eduCourseSubjectList.forEach(item => {
        if (item.educationTime) {
          const [hours, minutes] = item.educationTime.split(":").map(part => parseInt(part, 10));
          totalMinutes += (hours * 60) + minutes;
        }
      });
      const totalHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      // 계산된 총 시간을 표시, 앞에 '0'을 붙여 두 자리 숫자로 표시
      const formattedHours = totalHours.toString().padStart(2, '0');
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.educationInfo.sumEducationTime = `${formattedHours}:${formattedMinutes}`;
      return this.educationInfo.sumEducationTime; // 계산된 총 시간을 반환
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.saveUrl = transactionConfig.sop.edu.course.insert.url;
      this.insertUrl = transactionConfig.sop.edu.course.insert.url;
      this.updateUrl = transactionConfig.sop.edu.course.update.url;
      this.deleteUrl = transactionConfig.sop.edu.course.delete.url;
      this.checkUrl = selectConfig.sop.edu.course.check.url;
      this.resultCountUrl = selectConfig.sop.edu.result.count.url

      this.attachInfo.taskKey = this.popupParam.eduCourseId || ''
      
      if(!parseInt(this.educationInfo.eduQuestionPassScore)>0) this.educationInfo.eduQuestionPassScore = null
      console.log(this.educationInfo.eduQuestionPassScore)

      this.$comm.getComboItems('EDU_METHOD_CD').then(_result => {
        this.$_.forEach(_result, _item => {
          if (_item.attrVal1 == 'Y') _item.disable = true
          else _item.disable = false
        })
        this.comboItems = _result
      });
    },
    educationMethodChange() {
      if (this.educationInfo.educationMethodCd !== 'EMC999999') {this.educationInfo.vod = null} 
    },
    files(fileList) {
      this.educationVodItems = [];
      this.$_.forEach(fileList, _item => {
        if (_item.contentType == 'video/mp4') {
          this.educationVodItems.push({
            code: _item.sysAttachFileId,
            codeName: _item.oriFileNm,
          })
        }
      })
      if (fileList.length > 0) {
        this.$_.forEach(fileList, _item => {
          if (_item.contentType == 'video/mp4') {
            this.$_.forEach(this.comboItems, _item => {_item.disable = false;
              return
            })
          }
        })
        
      } else {
        this.$_.forEach(this.comboItems, _item => {
          if (_item.attrVal1 === 'Y') _item.disable = true;
          else _item.disable = false;
        })
      }
    },
    addItem() {
      this.educationInfo.eduCourseSubjectList.splice(0, 0, {
        eduCourseId: this.popupParam.eduCourseId,
        subjectNo: uid(),
        subjectName: '', // 교육명
        educationTime: '01:00', // 교육시간
        instructorName: '', // 강사명
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.educationInfo.deleteEduCourseSubjectList) {
            this.educationInfo.deleteEduCourseSubjectList = []
          }
          if (this.$_.findIndex(this.educationInfo.deleteEduCourseSubjectList, { subjectNo: item.subjectNo }) === -1
            && item.editFlag !== 'C') {
              this.educationInfo.deleteEduCourseSubjectList.push(item)
          }
          this.educationInfo.eduCourseSubjectList = this.$_.reject(this.educationInfo.eduCourseSubjectList, item)
        })
      }
    },
    saveInfo() {
      if (this.popupParam.eduCourseId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.educationInfo.eduQuestionFlag == 'N') {
            this.educationInfo.eduQuestionMstId = null;
            this.educationInfo.eduQuestionPassScore = null;
          } else {
            if (this.educationInfo.eduQuestionPassScore == '0') {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message: '문제풀이 통과 기준점수를 입력하세요.', // 문제풀이 통과 기준점수를 입력하세요.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          }
          let validPass = true;
          if (this.educationInfo.eduCourseSubjectList && this.educationInfo.eduCourseSubjectList.length > 0) {
            validPass = this.$comm.validTable(this.grid.columns, this.educationInfo.eduCourseSubjectList)
          }
          if (validPass) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.educationInfo.regUserId = this.$store.getters.user.userId;
                this.educationInfo.chgUserId = this.$store.getters.user.userId;
              
                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.eduCourseId) {
        this.$emit('changeStatus')
        this.attachInfo.taskKey = result.data
        this.attachInfo.isSubmit = uid()
        this.popupParam.eduCourseId = result.data;
      }
      this.$emit('getDetail')
    },
    removeInfo() {
      this.$http.url = this.$format(this.resultCountUrl, this.popupParam.eduCourseId)
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        if (_result.data > 0) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            // 해당 교육과정은 사용중인 교육계획이 존재하여\r\n삭제할 수 없습니다.
            message: '해당 교육과정은 사용중인 교육계획이 존재하여 삭제할 수 없습니다.', 
            type: 'info', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '삭제하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.$format(this.deleteUrl, this.popupParam.eduCourseId);
              this.$http.type = 'DELETE';
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                
                this.$emit('closePopup')
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      },);
    },
    legalDatachange() {
      if (this.educationInfo.legalEducationFlag !== 'Y') {
        this.educationInfo.educationCycle = null
      }
    },
    input(value){
      if(value==='Y') this.educationInfo.eduQuestionPassScore = 80
      else this.educationInfo.eduQuestionPassScore = null
    }
  }
};
</script>
<style lang="sass">
.attach 
  .upload-cover
    margin-top: -5px !important
    .custom-upload, .uploaded-file-list
      min-height: 300px !important
      max-height: 300px !important
</style>